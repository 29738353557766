import {Button} from 'components/Button';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {IProps as ILocationsListProps, LocationsList} from 'modules/locations/components/LocationsList';
import {useSelectLocation} from 'modules/locations/hooks/useSelectLocation';
import {useUserLocation} from 'modules/locations/hooks/useUserLocation';
import {selectIsLocationsListLoading} from 'modules/locations/selectors';
import {changeLocation} from 'modules/locations/thunks';
import {ModalContent} from 'modules/modals/components/ModalContent';
import {ModalTitle} from 'modules/modals/components/ModalTitle';
import {RegularModal} from 'modules/modals/components/RegularModal';
import {hideModal} from 'modules/modals/thunks';
import {PortalContext} from 'modules/portal/context/PortalContext';
import {Location} from 'new-models';
import React, {memo, MouseEventHandler, useCallback, useContext, useEffect, useState} from 'react';

import style from './style.module.scss';

export const ChooseLocationModal = memo(() => {
    const dispatch = useAppDispatch();
    const userLocation = useUserLocation();
    const selectLocation = useSelectLocation();

    const {visibility, name} = useContext(PortalContext);

    const isLocationLoading = useAppSelector(selectIsLocationsListLoading);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(userLocation);

    const isMobile = useIsMobile();

    const handleLocationChange = useCallback<NonNullable<ILocationsListProps['onSelect']>>((location) => {
        setSelectedLocation(location);
    }, []);

    const handleLocationChoose = useCallback<MouseEventHandler<HTMLButtonElement>>(async () => {
        if (!selectedLocation) {
            return;
        }

        await dispatch(changeLocation(selectedLocation));
    }, [dispatch, selectedLocation]);

    const handleCancel = useCallback(() => {
        if (!selectedLocation) {
            return;
        }

        selectLocation(selectedLocation);
    }, [selectLocation, selectedLocation]);

    useEffect(() => {
        if (isMobile || !visibility) {
            return;
        }
        dispatch(hideModal(name));
    }, [dispatch, isMobile, name, visibility]);

    return (
        <RegularModal adaptive className={style.modal} hasClose onClose={handleCancel}>
            <ModalTitle title="Выбор города" />
            <ModalContent>
                <LocationsList
                    checkedLocationId={selectedLocation?.id}
                    className={style.list}
                    onSelect={handleLocationChange}
                />

                <div className={style.actions}>
                    <Button
                        data-modal-action="close"
                        disabled={isLocationLoading || !selectedLocation}
                        isStretched
                        onClick={handleLocationChoose}
                        size="medium"
                        theme="primary"
                    >
                        Выбрать
                    </Button>
                    <Button
                        data-modal-action="close"
                        disabled={isLocationLoading}
                        isStretched
                        onClick={handleCancel}
                        size="medium"
                        theme="secondary"
                    >
                        Отменить
                    </Button>
                </div>
            </ModalContent>
        </RegularModal>
    );
});

export default ChooseLocationModal;
