import {OnlyMobile} from 'components/OnlyMobile';
import ChooseLocationModal from 'modules/locations/components/ChooseLocationModal';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import React, {memo} from 'react';

export const AdaptiveLocationChooseModal = memo(function AdaptiveCityChooseModal() {
    return (
        <OnlyMobile>
            <Modal name={MODALS.ADAPTIVE_LOCATION_CHOOSE.name} willMount>
                <ChooseLocationModal />
            </Modal>
        </OnlyMobile>
    );
});

export default AdaptiveLocationChooseModal;
